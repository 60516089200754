<template>
  <div class="manage-demos">
    <h2>{{ $t("manageDemos") }}</h2>
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      multi-sort
      class="mt-8 elevation-0"
    >
      <!-- Top Toolbar -->
      <template v-slot:top>
        <v-toolbar flat class="data-table-top-layout">
          <v-spacer />
          <v-col cols="5">
            <a-text-field
              v-model="search"
             :label="$t('search')"
              append-icon="mdi-magnify"
            ></a-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <!-- !Top Toolbar -->
      <!-- Customize Columns -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="data-table-actions-layout">
          <v-btn
            small
            class="mt-2 mb-2"
            color="red lighten-1 white--text"
            style="text-transform: unset !important"
            @click.stop="deleteItem(item)"
          >
            <v-icon small left>mdi-delete</v-icon>
            {{$t('delete')}}
          </v-btn>
        </div>
      </template>
      <!-- !Customize Columns -->
    </v-data-table>
    <!-- Delete Item Dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="500px"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-container class="pa-4">
          <v-row class="mb-4">
            <v-card-title class="text-h5">{{$t('delete')}} </v-card-title>
            <v-spacer />
            <v-btn icon color="primary" class="mt-3" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-divider />
          <div class="mx-4">
            <p class="my-6">
              {{$t('confirmDemoDelete')}}
            </p>
            <div class="form-layout mx-2">
              <v-row>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('company')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ company }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('name')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ name }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('email')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ email }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('requestDate')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ requestDate }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <v-card-actions class="mt-4 mr-n4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeDialog"
                style="text-transform: unset !important"
              >
                {{$t('close')}}
              </v-btn>
              <v-btn
                color="red lighten-1 white--text"
                class="mr-2"
                style="text-transform: unset !important"
                @click="deleteItemConfirm"
              >
                {{$t('delete')}}
              </v-btn>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- !Delete Item Dialog -->
  </div>
</template>
<script>
import ATextField from "@/components/ATextField.vue";
import AppError from "@/utils/AppError.js";
export default {
  name: "manage-demos",

  components: {
    ATextField,
  },

  mounted: function () {
    this.loadData();
  },

  data() {
    return {
      // Data table
      search: null,
      selectedIndex: -1,
      sortBy: ["request_date"],
      sortDesc: [false],

      company: null,
      name: null,
      email: null,
      requestDate: null,

      //Dialogs
      deleteDialog: false,

      // Data
      data: [],
    };
  },

  computed: {
    headers() {
      return [
        { text: this.$t("company"), value: "company" },
        { text: this.$t("name"), value: "name" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("requestDate"), value: "request_date" },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          filterable: false,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    /**
     * Get the demos.
     * @public
     */
    loadData() {
      // Load form data
      const loadFormData = this.$api({
        url: this.$config.api.route.demos,
        method: "GET",
      }).then((resp) => {
        console.log(resp.data)
        this.data = resp.data;
      });
      // Async handle loads
      this.$store.dispatch("util/startLoading");
      Promise.all([loadFormData])
        .catch(() => {
          AppError.handle(new AppError("LOAD_DATA_UNKNOWN"));
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Delete user.
     * @public
     */
    deleteItemConfirm() {
      const item = this.data[this.selectedIndex];
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.demosAction;
      url = url.replace(":id", item.id);
      this.$api({
        url: url,
        method: "DELETE",
      })
        .then(() => {
          this.closeDialog();
          this.loadData();
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t('demoDeleted')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Set the delete dialog.
     * @param {demo} item - the demo.
     * @public
     */
    deleteItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.deleteDialog = true;
    },

    /**
     * Close all the dialogs.
     * @public
     */
    closeDialog() {
      this.deleteDialog = false;
      
    },
     resetInputFields(){
      this.$nextTick(() => {
        this.resetFields();
        this.selectedIndex = -1;
      });
    },
    /**
     * Reset the form dialog.
     * @public
     */
    resetFields() {
      this.company = null;
      this.name = null;
      this.email = null;
      this.requestDate = null;
    },
    /**
     * Set the user details.
     * @param {user} item - the user.
     * @public
     */
    setForm(item) {
      this.company = item.company;
      this.name = item.name;
      this.email = item.email;
      this.requestDate = item.request_date;
    },
  },
};
</script>
